/**
 * create by pei 2019/03/19
 */
$(document).ready(function() {
    $("#metismenu li a").each(function(index){
        if ($($(this))[0].href == String(window.location)){
            $(this).parent().addClass("active");
            if ($($(this))[0].href == String(window.location)){
                $(this).parent().addClass("active");
                if(index===0){
                    $(this).find("span img").attr("src","../../assets/images/myPages/accountCheck.png");
                }else if(index===1){
                    $(this).find("span img").attr("src","../../assets/images/myPages/orderCheck.png");
                }else if(index===2){
                    $(this).find("span img").attr("src","../../assets/images/myPages/uploadCheck.png");
                }else if(index===3){
                    $(this).find("span img").attr("src","../../assets/images/myPages/planCheck.png");
                }else if(index===4){
                    $(this).find("span img").attr("src","../../assets/images/myPages/settingCheck.png");
                }
            }
        }
           
    })
    $("#navMenu li a").each(function(){
        if ($($(this))[0].href == String(window.location)){
            $(this).parent().addClass("nav-active");
        }
    })
    if(localStorage.getItem("userToken")){
        $(".login").css("display","none");
        $(".resgister").css("display","none");
        var corporName=localStorage.getItem("corporName");
        $(".navRight").html("<a href='./accountInformation.html'  class='tips'><span>"+corporName+"</span><span><img src='../../assets/images/三角.svg' alt=''></span><span><img src='../../assets/images/myPages/user.png' alt=''></span></a>");
        $(".navRight").append(content())
        function content() { 
            var data = $("<ul class='menutips'><li> <a href='../../accountInformation.html'>账号信息</a></li>" + 
                 "<li><a href='../../myOrder.html'>我的订单</a></li>"+ 
                 "<li><a href='../../uploadAdvertisement.html'>广告上传</a></li>"+ 
                 "<li><a href='../../playbackPlan.html'>播放计划</a></li>"+
                 "<li class='exitLogin'><a href='http://www.95009.net/host/loginOut'>退出登录</a></li>"
                 ); 
              
            return data; 
          } 
      $(".navRight").hover(function (){  
            $(".menutips").show();  
        },function (){  
            $(".menutips").hide();  
        }); 
    }else{
        $(".navRight").html('<div class="login"><a href="login.html">登陆</a></div><div class="resgister"><a href="register.html">注册</a></div>')
    }
    /**
     * 退出登录
     */
    $(".exitLogin").click(function(){
        localStorage.removeItem('userToken');
    })
    /**
     * 出来火狐浏览器的自适应问题
     */
    $(".wrapper-content").css("min-height",($(window).height()-135)+"px")
    $(".mainContent").css({"min-height":($(window).height()-135)+"px","overflow":"hidden"})
   
})
var timestamp =(new Date()).valueOf();
var expired=localStorage.getItem("expired")
function convertDateFromString(dateString) { 
        if (dateString) { 
        var arr1 = dateString.split(" "); 
        var sdate = arr1[0].split(':'); 
        var sdate1=arr1[1].split(':'); 
        var date = new Date(sdate[0], sdate[1]-1, sdate[2],sdate1[0], sdate1[1]); 
        return date;
        } 
}
var timeEnd;
if(localStorage.getItem("expired")){
    timeEnd=convertDateFromString(expired).valueOf();
}
function refreshToken(callback){
    if(timeEnd-timestamp<30*60*1000&&timeEnd-timestamp>0){
        $.ajax({
            url: "/host" + "/token/refresh",
            beforeSend: function (xhr) {
                xhr.setRequestHeader("Authorization", localStorage.getItem("userToken"));
            },
            dataType: "json",
            type: 'GET',
            success: function (data) {
                if(data.errCode===0){
                    localStorage.removeItem('userToken');
                    localStorage.removeItem('expired');
                    var userToken=data.data.tokenHead+data.data.token;
                    localStorage.setItem("userToken", userToken);
                    localStorage.setItem("expired", data.data.expired);
                    callback(localStorage.getItem("userToken"))
                }else{
                    alert(data.errMsg)
                }
                
            },
            error: function (err) {
                console.log(err)
            }
        })
    }else if(timeEnd-timestamp<0){
        localStorage.removeItem('userToken');
        callback(null);
    }else{
        callback(localStorage.getItem("userToken"));
    }
}

$("body").append("<div class='shade'><img src='' alt=''><div>")
//点击示范图片放大查看
$("body").on("click", ".C_seeBigImg", function (e) {
    $(".shade").css("display", "block");
    var src = $(this).attr("src");
    $(".shade>img").attr("src", src);
    setTimeout(function () {
        $(".shade>img").css({ "width": "800px", "height": "auto" });
    }, 10);
});

//隐藏图片详情查看
$(".shade").click(function () {
    setTimeout(function () {
        $(".shade").css({ "background": "rgba(255,255,255,0)" });
        $(".shade>img").css({ "width": "0px", "height": "auto" });
    }, 1);
    setTimeout(function () {
        $(".shade").css({ "display": "none", "width": "100%", "height": "100%", "background": "rgba(175,171,171,0.8)" });
    }, 301);
});
if ($(".C_loading").length == 0) {
    $("#wrapper").after('<div class="C_loading" style="display: none">' +
        '<div class="C_loadingMsg">' +
        '<img src="assets/images/loading.gif">' +
        '<div class="C_loadingText"></div>' +
        '</div>' +
        '</div>')
}
var loadingTimer = null;

/**
 * 加载提醒
 * @param text 提示文本
 */
function loadingMsg(text) {
    if ($(".C_loading").css("display") == "none") {
        $(".C_loading").css("display", "block");
        setTimeout(function () {
            $(".C_loadingText").html(text + ".");
        }, 0);
        setTimeout(function () {
            $(".C_loadingText").html(text + "..");
        }, 500);
        setTimeout(function () {
            $(".C_loadingText").html(text + "...");
        }, 1000);
        loadingTimer = setInterval(function () {
            setTimeout(function () {
                $(".C_loadingText").html(text + ".");
            }, 0);
            setTimeout(function () {
                $(".C_loadingText").html(text + "..");
            }, 500);
            setTimeout(function () {
                $(".C_loadingText").html(text + "...");
            }, 1000);
        }, 1500);
    }
}

/**
 * 加载提醒隐藏
 */
function loadingHide() {
    $(".C_loading").css("display", "none");
    if (loadingTimer) {
        clearInterval(loadingTimer);
    }
    setTimeout(function () {
        $(".C_loadingText").html("");
    }, 2000)
}

